import React, {useState, useRef} from "react"
import PageTitle from "../components/PageTitle"
import Seo from "../components/seo"
import {contactHolder, mailingListHolder} from '../styles/PageStyles.module.scss'


const ContactPage = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const formRef = useRef()

  const updateName = (e) => setName(e.target.value)
  const updateEmail = (e) => setEmail(e.target.value)
  
  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        "email": email,
        "name": name
      })
    }).then(() => {
      setLoading(false)
      setResult('success')
    })
    .catch(error => {
      setResult('error')
      setLoading(false)
      alert(error)
    })

  }


  const resetForm = () => {
    setLoading(false)
    setResult(null)
  }

  
  return (
  <article className={contactHolder}>
  <Seo title="Contact Dot Allison" />
    <PageTitle>Join the Mailing List</PageTitle>
    {/* <section className={socialsHolder}>
      <ul>
        <li><a className="socialLink" href="https://www.instagram.com/deetiebelle" target="_blank" rel="noreferrer"><div className="socialIcon"><Instagram /></div><div className="socialDescription"><span>Follow Dot on Instagram</span></div></a></li>
        <li><a className="socialLink" href="https://twitter.com/DotAllisonmusic" target="_blank" rel="noreferrer"><div className="socialIcon"><Twitter /></div><div className="socialDescription"><span>Follow Dot on Twitter</span></div></a></li>
        <li><a className="socialLink" href="mailto:maggie.rodford@air-edel.co.uk"><div className="socialIcon"><Email /></div><div className="socialDescription"><span>Mgmt: Maggie Rodford</span><span className="emailAddress">(maggie.rodford@air-edel.co.uk)</span></div></a></li>
      </ul>
    </section> */}


    <section className={mailingListHolder}>
      {/* <h2>Sign up to the Mailing List:</h2> */}


      { (result===null) ? 
        <form name="mailingListForm" method="POST" data-netlify="true" netlify-honeypot="bot-field" id="mailing-list-form" ref={formRef} onSubmit={(e)=>submit(e)}>
          <input type="hidden" name="form-name" value="mailingListForm" />
          <input type="text" name="name" placeholder="Your name" required value={name} onChange={(e)=>updateName(e)} />
          <input type="email" name="email" id="email" placeholder="Your email" required value={email} onChange={(e)=>updateEmail(e)} />
          <input type="submit" value="Add me to the Mailling List"/>
        </form>
        : 
        <div className="results">
          {
            (result==='success') ?
            <p>Thanks for subscribing!</p>
            :
            <div>
              <p>Sorry, something went wrong:</p>
              <p><button disabled={loading} onClick={()=>{ resetForm() }}>Try again</button></p>
            </div>
          }
        </div>
        }



    </section>
  </article>
)
}

export default ContactPage
